import service from "@/api/service";
import { SearchRequest } from "@/api/booking-shop/request";
import { GetResponse, SearchResponse } from "@/api/booking-shop/response";

/**
 * 予約店舗一覧・検索APIをコールします。
 *
 * @param searchRequest 予約店舗検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-booking-shop", searchRequest);
  return response.data as SearchResponse;
}

/**
 * 予約確認店舗情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-booking-shop");
  return response.data as GetResponse;
}
